<template>
  <div class="modalDescriptionFile">
    <div class="modal" :class="{ 'is-active': isActive }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="card p-4">
          <h1 class="is-size-4 titleModal">Cargar archivo</h1>
          <h2 class="is-size-6 infoModal is-light my-2">
            Busca un archivo para subir
          </h2>
          <div class="columns is-multiline mb-0">
            <div class="column is-9">
              <b-field>
                <b-input
                  v-model="infoFile.description"
                  type="text"
                  placeholder="Descripción del archivo"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3 is-flex is-align-items-center">
              <b-field>
                <b-upload
                  v-model="infoFile.file"
                  :accept="accept"
                  @input="validateSize"
                >
                  <span :class="`button is-small buttonSearchFile is-${color}`">
                    <span class="file-label">Buscar archivo</span>
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-12 pt-0">
              <div v-if="infoFile.file" class="is-flex is-align-items-center">
                <span :class="`has-text-${color} is-underlined`">
                  {{ infoFile.file.name }}
                </span>
                <span class="icon has-text-red pointer is-size-4 ml-3" @click="clear()">
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </div>
            </div>
          </div>
          <span class="is-size-7" v-if="!infoFile.file">
            {{message}}
          </span>
          <div class="is-divider mt-1 mb-4"></div>
          <div class="buttons is-centered">
            <button
              :class="`button buttonFileUpload is-${color}`"
              @click="uploadInfo()"
              :disabled="$v.$invalid"
            >
              Cargar
            </button>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal()"
      ></button>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "UploadImageModal",
  props: {
    isActive: {
      default: true,
    },
    color: {
      type: String,
    },
    accept: {
      type: String,
    },
    message: {
      type: String,
      default: 'El archivo debe tener un tamaño máximo de 20 MB'
    }
  },
  data() {
    return {
      infoFile: {},
    };
  },
  validations: {
    infoFile: {
      description: { required },
      file: { required },
    },
  },
  methods: {
    clear() {
      this.infoFile.file = null;
    },
    closeModal() {
      this.$emit("close");
    },
    uploadInfo() {
      this.$emit("uploadFileInfo", this.infoFile);
      this.infoFile = {};
      this.closeModal();
    },
    validateSize(file) {
      if (file.size > 20000000) {
        this.infoFile.file = null;
        this.$buefy.toast.open({
          duration: 5000,
          message: "El archivo cargado no puede superar los 20MB.",
          position: "is-bottom",
          type: "is-warning",
        });
      }
      return;
    },
  },
};
</script>

<style scoped lang="scss">
.modalDescriptionFile {
  .modal-content {
    width: 550px;
  }
  .titleModal {
    color: #4f4f4f;
    font-family: "Roboto", sans-serif;
  }
  .infoModal {
    color: #4f4f4f;
    font-size: 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  .buttonSearchFile {
    font-size: 12px;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    border-radius: 5px;
  }
  .buttonFileUpload {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #ffffff;
    border-radius: 8px;
    width: 260px;
    height: 45px;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
