<template src="./FormCourseFilled.html">
</template>

<script>

import coursesService from "@/services/Courses";
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";

export default {
  name: "FormCourseFilled",
  components: {
    UploadImageModal
  },
  data() {
    return {
      file: null,
      courseName: '',
      universityName: '',
      courseLink: '',
      level: '',
      imageDescription: 'fotoDescripción',
      type: '',
      course: "type",
      AllCoursesByInstitution: [],
      selectedCourse: null,
      isActive: false,
      infoFile: {},
      dataStructure: {}
    }
  },
  methods: {
    async getCourseFormInformation() {
      try {
        this.dataStructure = await coursesService.getPreEnrollCourse(this.$route.params.formId);
        console.log('this.dataStructure', this.dataStructure);
      } catch (e) {
        console.log('e', e);
      }
    },
    async updatePreEnrollCourse(status) {
      try {
        this.dataStructure.status = status;
        Reflect.deleteProperty( this.dataStructure, 'codeCourse');
        Reflect.deleteProperty( this.dataStructure, 'formEnrollCourseId');
        Reflect.deleteProperty( this.dataStructure, 'id');
        Reflect.deleteProperty( this.dataStructure, 'userId');
        await coursesService.updatePreEnrollCourse(this.$route.params.formId, this.dataStructure);
        await this.$router.push({name:'AdminPanelAcademic'});
      } catch (e) {
        console.log('e', e);
      }
    },
    async createRecommendedCourse() {
      try {
        const imageLink = await coursesService.uploadFile('imagesRecommendedCourse', 'imagesRecommendedCourse', this.infoFile.name, this.infoFile.file);
        console.log('url', imageLink);
        const info = {
          name: this.courseName,
          level: this.level,
          url: this.courseLink,
          image: imageLink,
          imageDescription: this.infoFile.description,
          type: this.type,
          institution: this.universityName,
          course: this.course
        }
        await coursesService.createRecommendedCourse(info);
        this.$router.push({name: "RecommendedCourses"});
        this.$buefy.toast.open({
          duration: 5000,
          message: "Curso recomendado creado correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (e) {
        console.log('e', e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Error creando curso!",
          type: "is-error",
        });
      }
    },
  },
  mounted() {
  },
  beforeMount() {
    this.getCourseFormInformation();
  }
}
</script>

<style scoped lang="scss" src="./FormCourseFilled.scss">
</style>
